














import {
  CubeTexture,
  CubeTextureLoader,
  PerspectiveCamera,
  Scene,
  Texture,
  WebGLRenderer
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Vue from 'vue'
import { mapActions } from 'vuex'

import { CustomDeviceOrientationControls } from '@/services/CustomDeviceOrientationControls'
import type { TextureMap } from '@/utils/panorama'
import { panoramaImages } from '@/utils/panorama'

export default Vue.extend({
  name: 'PanoramaViewer',

  props: {
    dimmerLevel: {
      type: Number,
      required: true
    },
    permission: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      loading: true,
      loadingProgress: 0,
      camera: new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000),
      scene: new Scene(),
      renderer: new WebGLRenderer(),
      controls: {} as OrbitControls | CustomDeviceOrientationControls,
      animationRequest: 0,
      backgrounds: [] as Texture[]
    }
  },

  watch: {
    dimmerLevel () {
      this.scene.background = this.backgrounds[this.dimmerLevel - 1]
    }
  },

  async mounted () {
    await this.init()
  },

  beforeDestroy () {
    cancelAnimationFrame(this.animationRequest)
  },

  methods: {
    ...mapActions(['checkDeviceMotionPermission']),

    init: async function () {
      this.scene.add(this.camera)
      this.renderer.setSize(window.innerWidth, window.innerHeight)

      await this.loadTexture()

      this.scene.background = this.backgrounds[this.dimmerLevel - 1]

      this.$el.appendChild(this.renderer.domElement)

      this.setControls()

      this.render()
    },

    async loadTexture () {
      this.loading = true

      this.backgrounds = []

      for (let i = 0; i < 3; i++) {
        this.backgrounds[i] = await this.loadCubeMap(panoramaImages[i])
        this.loadingProgress = (i + 1) / 3 * 100
        await this.$nextTick()
        await this.$nextTick()
      }

      await this.$nextTick()

      this.loading = false
    },

    async loadCubeMap (images: TextureMap) {
      return new Promise<CubeTexture>(resolve => {
        new CubeTextureLoader()
          .load([
            images.left,
            images.right,
            images.top,
            images.bottom,
            images.back,
            images.front
          ], texture => resolve(texture))
      })
    },

    setControls () {
      this.controls = this.permission
        ? new CustomDeviceOrientationControls(this.camera)
        : new OrbitControls(this.camera, this.renderer.domElement)

      if ('rotateSpeed' in this.controls) {
        this.camera.position.set(0, 0, 1000)

        this.controls.rotateSpeed = -0.2
        this.controls.maxPolarAngle = Math.PI / 1.5
        this.controls.minPolarAngle = Math.PI / 2
      }
    },

    render () {
      this.renderer.render(this.scene, this.camera)
      this.animationRequest = requestAnimationFrame(this.render)

      this.controls.update()
    }
  }
})

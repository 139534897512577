import {
  Euler,
  EventDispatcher,
  MathUtils, PerspectiveCamera,
  Quaternion,
  Vector3
} from 'three'

const _zee = new Vector3(0, 0, 1)
const _euler = new Euler()
const _q0 = new Quaternion()
const _q1 = new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)) // - PI/2 around the x-axis

const _changeEvent = { type: 'change' }

class CustomDeviceOrientationControls extends EventDispatcher {
  object: PerspectiveCamera
  enabled: boolean
  deviceOrientation: DeviceOrientationEvent
  screenOrientation: number
  alphaOffset: number

  connect: any
  disconnect: any
  update: any
  dispose: any
  clamp: any

  constructor (object: PerspectiveCamera) {
    super()

    if (!window.isSecureContext) {
      console.error('THREE.DeviceOrientationControls: DeviceOrientationEvent is only available in secure contexts (https)')
    }

    const EPS = 0.000001
    const lastQuaternion = new Quaternion()

    this.object = object
    this.object.rotation.reorder('YXZ')

    this.enabled = true

    this.deviceOrientation = {} as DeviceOrientationEvent
    this.screenOrientation = 0

    this.alphaOffset = 0 // radians

    const onDeviceOrientationChangeEvent = (event: DeviceOrientationEvent) => {
      this.deviceOrientation = event
    }

    const onScreenOrientationChangeEvent = () => {
      this.screenOrientation = window.orientation as number || 0
    }

    // The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''
    const setObjectQuaternion = function (quaternion: Quaternion, alpha: number, beta: number, gamma: number, orient: number) {
      _euler.set(beta, alpha, -gamma, 'YXZ') // 'ZXY' for the device, but 'YXZ' for us

      quaternion.setFromEuler(_euler) // orient the device

      quaternion.multiply(_q1) // camera looks out the back of the device, not the top

      quaternion.multiply(_q0.setFromAxisAngle(_zee, -orient)) // adjust for screen orientation
    }

    this.connect = function () {
      onScreenOrientationChangeEvent() // run once on load

      window.addEventListener('orientationchange', onScreenOrientationChangeEvent)
      window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent)

      this.enabled = true
    }

    this.disconnect = function () {
      window.removeEventListener('orientationchange', onScreenOrientationChangeEvent)
      window.removeEventListener('deviceorientation', onDeviceOrientationChangeEvent)

      this.enabled = false
    }

    this.update = function () {
      if (!this.enabled) return

      const device = this.deviceOrientation

      // alpha = rotation z achse
      // beta = rotation x achse
      // gamma = rotation y achse

      if (device) {
        const alpha = device.alpha ? MathUtils.degToRad(device.alpha) + this.alphaOffset : 0 // Z

        const beta = device.beta ? MathUtils.degToRad(device.beta) : 0 // X'

        const gamma = device.gamma ? MathUtils.degToRad(device.gamma) : 0 // Y''

        const orient = this.screenOrientation ? MathUtils.degToRad(this.screenOrientation) : 0 // O

        setObjectQuaternion(this.object.quaternion, alpha, this.clamp(beta, 1.5, 2), gamma, orient)

        if (8 * (1 - lastQuaternion.dot(this.object.quaternion)) > EPS) {
          lastQuaternion.copy(this.object.quaternion)
          this.dispatchEvent(_changeEvent)
        }
      }
    }

    this.clamp = (angle: number, min: number, max: number): number => {
      return Math.min(Math.max(angle, min), max)
    }

    this.dispose = function () {
      this.disconnect()
    }

    this.connect()
  }
}

export { CustomDeviceOrientationControls }
